<template>
  <div class="login">
    <!-- 登录 -->
    <el-form v-if="mode == 'login'" ref="login" :model="form1">
      <div class="title">{{ $t("login.title_sub") }}</div>
      <el-row>
        <el-form-item :label="$t('rigister.email')">
          <el-input
            v-model="form1.email"
            :placeholder="$t('rigister.emailTip')"
          ></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item :label="$t('rigister.password')">
          <el-input
            v-model="form1.password"
            type="password"
            :placeholder="$t('rigister.passwordTip')"
          ></el-input>
        </el-form-item>
      </el-row>
      <!-- <el-row :gutter="10">
				<el-col :span="16">
					<el-form-item label="图形验证码">
						<el-input placeholder="请输入图中所显示的验证码"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="empty" class="empty">
						<button class="picCode" >
							<PicCode :identifyCode="code" ></PicCode>
						</button>
					</el-form-item>
				</el-col>
			</el-row> -->
      <div class="forget" @click="mode = 'forget'">{{ $t("login.forget") }}</div>
      <div class="button" @click="login" ref="login">
        <template v-if="subIng"><i class="el-icon-loading"></i></template>
        <template v-else>{{ $t("login.title") }}</template>
      </div>
      <div class="devide"></div>
      <div class="title">{{ $t("login.new1") }}</div>
      <div class="tips">
        <div class="item">{{ $t("login.new2") }}</div>
        <div class="item">{{ $t("login.able1") }}</div>
        <div class="item">{{ $t("login.able2") }}</div>
        <div class="item">{{ $t("login.able3") }}</div>
        <div class="item">{{ $t("login.able4") }}</div>
        <div class="item">{{ $t("login.able5") }}</div>
      </div>
      <div class="button noBack" @click="mode = 'regist'">{{ $t("rigister.title") }}</div>
    </el-form>
    <!-- 注册 -->
    <el-form v-if="mode == 'regist'" ref="form" :model="form2">
      <div class="title">{{ $t("rigister.confrim") }}</div>
      <el-row>
        <el-form-item :label="$t('rigister.email')">
          <el-input
            v-model="form2.email"
            :placeholder="$t('rigister.emailTip')"
          ></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item :label="$t('rigister.password')">
          <el-input
            v-model="form2.password"
            type="password"
            :placeholder="$t('rigister.passwordTip')"
          ></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item :label="$t('rigister.con_password')">
          <el-input
            v-model="form2.rePassword"
            type="password"
            :placeholder="$t('rigister.repasswordTip')"
          ></el-input>
        </el-form-item>
      </el-row>
      <div class="button" @click="regist" style="margin-top: 40px">
        <template v-if="subIng"><i class="el-icon-loading"></i></template>
        <template v-else>{{ $t("rigister.title") }}</template>
      </div>
      <div class="switch">
        {{ $t("login.title_sub") }}?<span @click="mode = 'login'">{{
          $t("login.title")
        }}</span>
      </div>
    </el-form>
    <!-- 忘记密码 -->
    <el-form v-if="mode == 'forget'" ref="form" :model="form3">
      <div class="title">{{ $t("forget.title") }}</div>
      <el-row>
        <el-form-item :label="$t('rigister.email')">
            <el-input
              v-model="form3.email"
              class="email"
              :placeholder="$t('rigister.emailTip')"
            ></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item class="sendemail">
          <div class="send-label"> {{$t('rigister.code')}} </div>
          <div class="send-content">
            <el-input
              v-model="form3.code"
              class="email"
              :placeholder="$t('rigister.codeTip')"
            ></el-input>
            <button class="sendbtn" v-if="!isGettingCode" @click="sendCode">{{ $t('password.getCode') }}</button>
            <button class="sendbtn" v-else>{{ second }}s</button>
          </div>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item :label="$t('rigister.password')">
          <el-input
            v-model="form3.pwd"
            type="password"
            :placeholder="$t('rigister.passwordTip')"
          ></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item :label="$t('rigister.con_password')">
          <el-input
            v-model="form3.newPwd"
            type="password"
            :placeholder="$t('rigister.repasswordTip')"
          ></el-input>
        </el-form-item>
      </el-row>
      <div class="button" @click="forget" style="margin-top: 40px">
        <template v-if="subIng"><i class="el-icon-loading"></i></template>
        <template v-else>{{ $t("forget.confrim") }}</template>
      </div>
      <div class="switch">
        {{ $t("login.title_sub") }}?<span @click="mode = 'login'">{{
          $t("login.title")
        }}</span>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import PicCode from "../code/picCode.vue";
import yCode from "../code/yCode.vue";
import Api from "../../api/request.js";
const SECONDS = 60;
export default {
  name: "Login",
  components: {
    PicCode,
    yCode,
  },
  props: {
		type: {
			type: String,
			default: ''
		}
	},
  data() {
    return {
      isGettingCode: false,
      second: SECONDS,
      subIng: false,
      dontAgain: false,
      mode: "login",
      code: "8997",
      form1: {
        is_web: 1,
        email: "",
        password: "",
      },
      form2: {
        is_web: 1,
        email: "",
        password: "",
        rePassword: "",
      },
      form3: {
        is_web: 1,
        email: "",
        pwd: "",
        newPwd: "",
      },
    };
  },
  computed: {
    ...mapState([ 'cartTemp']),
  },
  created() {
    if(this.type){
      this.mode = this.type
    }
    console.log('-----------login-cartTemp---------', this.cartTemp)
    document.onkeyup = () => {
      var key = window.event.keyCode;
      if (key == 13) {
        if (this.$refs.login) {
          this.login();
        }
      }
    };
  },
  methods: {
    ...mapMutations(['setCartTemp']),
    login() {
      if (this.subIng) {
        this.$dialog.notify(this.$t("tip.title"), this.$t("tip.repeat"));
      } else {
        this.subIng = true;
        Api.Login.login(this.form1).then((res) => {
          if (res.data.status == "SUCCESSS") {
            this.$dialog.notify(this.$t("tip.loginSuc"), this.$t("tip.loginSuc_dec"));
            this.$storage.set("user_info", res.data.data);
            this.$store.state.isLogin = true;
            this.$parent.$parent._data.show.login = false;

            if(this.cartTemp && this.cartTemp.length>0){
                this.cartTemp.map((item) => {
                  this.$bus.$emit("addCart",item.skuId, item.num);
                  this.setCartTemp([]);
                })
            }
          }
          this.subIng = false;
        });
      }
    },
    regist() {
      if (this.subIng) {
        this.$dialog.notify(this.$t("tip.ing"), this.$t("tip.repeat"));
      } else if (this.dontAgain) {
        this.$dialog.notify(this.$t("tip.onemin"), this.$t("tip.emailRepeat"));
      } else {
        this.dontAgain = true;
        setTimeout((res) => {
          this.dontAgain = false;
        }, 60000);
        this.subIng = true;
        Api.Login.regist(this.form2)
          .then((res) => {
            if (res.data.status == "SUCCESSS") {
              this.$confirm(this.$t("login.created"), this.$t("login.title_sub"), {
                showCancelButton: false,
                confirmButtonText: this.$t("login.created"),
                confirmButtonClass: "confirm_btn",
              }).then((res) => {
                this.form1 = {
                  email: this.form2.email,
                  password: this.form2.password,
                  is_web: 1
                };
                this.mode = "login";
              });
            }
            this.subIng = false;
          })
          .catch((res) => {
            this.subIng = false;
            this.$dialog.notify(this.$t("tip.title"), this.$t("tip.emailErr"));
          });
      }
    },
    //忘记密码
    forget() {
      if (this.subIng) {
        this.$dialog.notify(this.$t("tip.ing"), this.$t("tip.repeat"));
      } else if (this.dontAgain) {
        this.$dialog.notify(this.$t("tip.onemin"), this.$t("tip.emailRepeat"));
      } else {
        this.dontAgain = true;
        setTimeout((res) => {
          this.dontAgain = false;
        }, 60000);
        this.subIng = true;
        Api.Login.resetpwd(this.form3)
          .then((res) => {
            if (res.data.status == "SUCCESSS") {
                this.form1 = {
                  email: this.form3.email,
                  password: this.form3.password,
                  is_web: 1
                };
                this.mode = "login";
            }
            this.subIng = false;
          })
          .catch((res) => {
            this.subIng = false;
            this.$dialog.notify(this.$t("tip.title"), this.$t("tip.emailErr"));
          });
      }
    },
    // 发送验证码
    sendCode() {
      if (!this.form3.email) {
        this.$dialog.notify(this.$t('rigister.emailTip'));
        return;
      }

      this.isGettingCode = !this.isGettingCode;

      this.timer = setInterval(() => {
        this.second = this.second - 1;
        if (this.second < 1) {
          this.isGettingCode = !this.isGettingCode;
          this.second = SECONDS;
          clearInterval(this.timer);
        }
      }, 1000);

      Api.Login.getEmailCode({ email: this.form3.email }).then(res => {
        console.log(res);
        if (res.data.code == 0) {
          this.$toasted.success(this.$t('password.sendSuccess'));
        } else {
          this.$dialog.notify(res.data.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  height: calc(100vh - 60px);
  overflow: auto;
}
.Protocol {
  margin: 20px 0;
  display: flex;
  align-items: center;
  & > span {
    font-size: 14px;
  }
  .check {
    width: 12px;
    height: 12px;
    background: #ffffff;
    border: 1px solid #757575;
    margin-right: 10px;
    cursor: pointer;
  }
}
.switch {
  font-size: 14px;
  margin-top: 20px;
  & > span {
    text-decoration: underline;
    cursor: pointer;
  }
}
.el-form {
  padding: 0 100px;
  .forget {
    font-size: 14px;
    color: #999999;
    line-height: 20px;
    margin: 12px 0 20px;
    text-decoration: underline;
    cursor: pointer;
  }
  .button {
    height: 38px;
    line-height: 38px;
    background: #123178;
    border-radius: 6px;
    border: 1px solid #123178;
    text-align: center;
    color: #fff;
    cursor: pointer;
    &.noBack {
      background: #fff;
      border: 1px solid #123178;
      text-align: center;
      color: #123178;
      &:hover {
        background-color: #efefef;
      }
    }
    &:hover {
      background-color: #021450;
    }
  }
  .devide {
    height: 1px;
    background: #d9d9d9;
    margin: 40px 0;
  }
  .tips {
    overflow: hidden;
    margin-bottom: 20px;
    .item {
      font-size: 14px;
      color: #000000;
      line-height: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      &:nth-child(n + 2)::before {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #000000;
        margin-right: 0.5em;
      }
    }
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    line-height: 25px;
    text-align: center;
    margin-bottom: 40px;
  }

  .sendemail .send-content{
    display: flex;
    flex-wrap: nowrap;
  }
  .sendemail .email{
    width: 70%;
    margin-right: 1%;
  }
  .sendbtn{
    background: #000;
    color: #fff;
    height: 40px;
    padding: 0 22px;
    line-height: 42px;
    text-align: center;
  }
  .el-row {
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
  }
  /deep/.el-form-item {
    margin-bottom: 0;
    &.empty {
      .el-form-item__label {
        opacity: 0;
      }
    }
    .picCode {
      display: block;
      width: 100%;
      height: 40px;
      background: #ccc;
      border: none;
    }
  }
  /deep/.el-form-item__label {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
    margin: 0 0 12px;
  }
  /deep/.el-input__inner {
    border-radius: 0;
    border-color: #757575;
  }
  /deep/.el-textarea__inner {
    border-radius: 0;
    border-color: #757575;
    height: 208px;
  }
  /deep/.el-select .el-input .el-select__caret {
    color: #000;
    font-size: 12px;
    font-weight: bolder;
  }
}
@media screen and (max-width: 650px) {
  .el-form {
    padding: 0 40px;
  }
}
</style>
